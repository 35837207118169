import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import IconWithText from '@shared/ui/display/IconWIthText';
import { maskCardNumber } from '@shared/lib/Helpers';
import { PrimaryButton, QuestionIcon, StepContainer, SecondaryButton, } from '../shared';
const Warning = ({ returnToEdit, handleDelete, initValues, }) => {
    const { t } = useTranslation();
    return (_jsx(StepContainer, { upperContent: (_jsx(IconWithText, { IconElement: _jsx(QuestionIcon, {}), text: t('settings.sidebars.edit_payment_method.steps.delete_method.text', {
                cardNumber: (initValues === null || initValues === void 0 ? void 0 : initValues.cardNumber) ? maskCardNumber(initValues.cardNumber) : '',
                ns: 'translation',
            }) })), bottomContent: (_jsxs(_Fragment, { children: [_jsx(PrimaryButton, { onClick: handleDelete, children: t('settings.sidebars.edit_payment_method.steps.delete_method.delete_btn_text', { ns: 'translation' }) }), _jsx(SecondaryButton, { onClick: returnToEdit, children: t('settings.sidebars.edit_payment_method.steps.delete_method.cancel_btn_text', { ns: 'translation' }) })] })) }));
};
export default Warning;
